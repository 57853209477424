"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.advancedPrompt = advancedPrompt;
exports.changeImageStyle = changeImageStyle;
exports.deleteImageStyle = deleteImageStyle;
exports.getAiImage = getAiImage;
exports.getAiImageSDXL = getAiImageSDXL;
exports.getImageStyle = getImageStyle;
exports.getPromptTag = getPromptTag;
exports.getSdSwitch = getSdSwitch;
exports.imageHistory = imageHistory;
exports.imageStar = imageStar;
exports.imageUnStar = imageUnStar;
exports.sdModels = sdModels;
exports.sdTxtToImg = sdTxtToImg;
exports.sdloras = sdloras;
exports.segmentImage = segmentImage;
exports.setSdSwitch = setSdSwitch;
exports.updateImage = updateImage;
exports.updateStyleImage = updateStyleImage;
exports.updateStyleImageByOss = updateStyleImageByOss;
var _request = _interopRequireDefault(require("@/plugins/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 获取画面风格 修饰词 艺术家
function getPromptTag(data) {
  return (0, _request.default)({
    url: '/api/images/prompt_tag',
    method: 'post',
    data
  });
}

// 文生图
function getAiImage(data) {
  return (0, _request.default)({
    url: '/api/images/ai_image',
    method: 'post',
    data
  });
}
// 文生图SDXL
function getAiImageSDXL(data) {
  return (0, _request.default)({
    url: '/api/images/ai_image_sdxl',
    method: 'post',
    data
  });
}

// 点赞收藏指定的图片
function imageStar(data) {
  return (0, _request.default)({
    url: '/api/images/image_star',
    method: 'post',
    data
  });
}

// 取消点赞收藏指定的图片
function imageUnStar(data) {
  return (0, _request.default)({
    url: '/api/images/image_unstar',
    method: 'post',
    data
  });
}

// 获取用户的图片生成历史信息/收藏的图片
function imageHistory(data) {
  return (0, _request.default)({
    url: '/api/images/image_history',
    method: 'post',
    data
  });
}

// 图片裁剪后更新接口
function updateImage(fileId, fileData) {
  return (0, _request.default)({
    url: `/api/images/update_image/?image_id=${fileId}`,
    method: 'post',
    data: fileData
  });
}

// 获取润色后的提示词
function advancedPrompt(data) {
  return (0, _request.default)({
    url: `/api/images/advanced_prompt`,
    method: 'post',
    data
  });
}

// 一键抠图
function segmentImage(data) {
  return (0, _request.default)({
    url: `/api/images/image_segment`,
    method: 'post',
    data
  });
}

// 上传风格迁移图片
// 0-原始图片 1-风格参考图片
function updateStyleImage(data) {
  return (0, _request.default)({
    url: `/api/images/update_style_image`,
    method: 'post',
    data
  });
}

// 用户输入图片url上传风格迁移图片
// 0-原始图片 1-风格参考图片
function updateStyleImageByOss(params) {
  return (0, _request.default)({
    url: `/api/images/update_style_image_by_oss`,
    method: 'post',
    params
  });
}

// 获取风格迁移相关图片信息
function getImageStyle(data) {
  return (0, _request.default)({
    url: `/api/images/get_image_style`,
    method: 'post',
    data
  });
}

// 图片风格迁移操作
function changeImageStyle(data) {
  return (0, _request.default)({
    url: `/api/images/change_image_style`,
    method: 'post',
    data
  });
}

// 删除风格图片
function deleteImageStyle(data) {
  return (0, _request.default)({
    url: `/api/images/delete_image_style`,
    method: 'post',
    data
  });
}

// 获取 Stable Diffusion 模型列表
function sdModels() {
  return (0, _request.default)({
    url: `/api/images/sd_models`,
    method: 'get'
  });
}

// 获取产品自定义模型列表
function sdloras() {
  return (0, _request.default)({
    url: `/api/images/sd_loras`,
    method: 'get'
  });
}

// Stable Diffusion 文生图
function sdTxtToImg(data) {
  return (0, _request.default)({
    url: `/api/images/sd_txt2img`,
    method: 'post',
    data
  });
}

// 获取定制模型开关
function getSdSwitch() {
  return (0, _request.default)({
    url: `/api/images/sd_switch`,
    method: 'get'
  });
}

// 设置定制模型开关
function setSdSwitch(data) {
  return (0, _request.default)({
    url: `/api/images/set_sd_switch`,
    method: 'post',
    data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _dom = require("view-ui-plus/src/utils/dom");
var _assist = require("view-ui-plus/src/utils/assist");
(0, _assist.setMatchMedia)();
var _default = {
  name: 'app',
  methods: {
    ...(0, _vuex.mapMutations)('admin/layout', ['setDevice', 'setBodyHeight']),
    handleWindowResize() {
      this.handleMatchMedia();
      this.handleSetBodyHeight();
    },
    handleMatchMedia() {
      const matchMedia = window.matchMedia;
      if (matchMedia('(max-width: 600px)').matches) {
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    },
    handleSetBodyHeight() {
      this.setBodyHeight(document.body.offsetHeight);
    }
  },
  mounted() {
    this.$Message.config({
      duration: 3
    });
    (0, _dom.on)(window, 'resize', this.handleWindowResize);
    this.handleMatchMedia();
    this.handleSetBodyHeight();

    //该属性控制pdf文档预览模块的跳转状态
    window.localStorage.setItem('pdfStatus', 'false');
  },
  beforeUnmount() {
    (0, _dom.off)(window, 'resize', this.handleWindowResize);
  }
};
exports.default = _default;
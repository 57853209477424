"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    userInfo: Object
  },
  data() {
    return {
      name: '',
      bgColor: ''
    };
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal !== null && newVal.hasOwnProperty('character')) {
          this.name = newVal.character;
          this.bgColor = newVal.color;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
var _logoSmall = _interopRequireDefault(require("@/assets/images/logo-small.png"));
var _logo_china = _interopRequireDefault(require("@/assets/images/logo_china.png"));
var _logoDark = _interopRequireDefault(require("@/assets/images/logo-dark.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _hoisted_1 = {
  key: 0,
  src: _logoSmall.default,
  alt: ""
};
const _hoisted_2 = {
  key: 1,
  src: _logo_china.default,
  alt: ""
};
const _hoisted_3 = {
  key: 2,
  src: _logoDark.default,
  alt: ""
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_link = (0, _vue.resolveComponent)("i-link");
  return (0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_link, {
    class: (0, _vue.normalizeClass)(["i-layout-header-logo", {
      'i-layout-header-logo-stick': !_ctx.isMobile
    }]),
    to: "/"
  }, {
    default: (0, _vue.withCtx)(() => [_ctx.isMobile ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_1)) : _ctx.headerTheme === 'light' ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_2)) : ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_3))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
var _logoSmall = _interopRequireDefault(require("@/assets/images/logo-small.png"));
var _logo = _interopRequireDefault(require("@/assets/images/logo.png"));
var _logoDark = _interopRequireDefault(require("@/assets/images/logo-dark.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _hoisted_1 = {
  key: 0,
  src: _logoSmall.default,
  alt: ""
};
const _hoisted_2 = {
  key: 1,
  src: _logo.default,
  alt: ""
};
const _hoisted_3 = {
  key: 2,
  src: _logoDark.default,
  alt: ""
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_link = (0, _vue.resolveComponent)("i-link");
  const _component_i_menu_side_item = (0, _vue.resolveComponent)("i-menu-side-item");
  const _component_i_menu_side_submenu = (0, _vue.resolveComponent)("i-menu-side-submenu");
  const _component_Tooltip = (0, _vue.resolveComponent)("Tooltip");
  const _component_i_menu_side_collapse = (0, _vue.resolveComponent)("i-menu-side-collapse");
  const _component_Menu = (0, _vue.resolveComponent)("Menu");
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", null, [(0, _vue.createElementVNode)("div", {
    class: (0, _vue.normalizeClass)(["i-layout-sider-logo", {
      'i-layout-sider-logo-dark': _ctx.siderTheme === 'dark'
    }])
  }, [(0, _vue.createVNode)(_vue.Transition, {
    name: "fade-quick",
    persisted: ""
  }, {
    default: (0, _vue.withCtx)(() => [(0, _vue.withDirectives)((0, _vue.createVNode)(_component_i_link, {
      to: "/"
    }, {
      default: (0, _vue.withCtx)(() => [_ctx.menuCollapse ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_1)) : _ctx.siderTheme === 'light' ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_2)) : ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_3))]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [[_vue.vShow, !$props.hideLogo]])]),
    _: 1 /* STABLE */
  })], 2 /* CLASS */), (0, _vue.createVNode)(_component_Menu, {
    ref: "menu",
    class: (0, _vue.normalizeClass)(["i-layout-menu-side i-scrollbar-hide", {
      'i-layout-menu-side-collapse': _ctx.menuCollapse
    }]),
    theme: _ctx.siderTheme,
    accordion: _ctx.menuAccordion,
    "active-name": _ctx.activePath,
    "open-names": _ctx.openNames,
    width: "auto"
  }, {
    default: (0, _vue.withCtx)(() => [((0, _vue.openBlock)(true), (0, _vue.createElementBlock)(_vue.Fragment, null, (0, _vue.renderList)($data.menuSider, (item, index) => {
      return (0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, {
        key: index
      }, [!_ctx.menuCollapse ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, {
        key: 0
      }, [item.children === undefined || !item.children.length ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_item, {
        key: 0,
        menu: item
      }, null, 8 /* PROPS */, ["menu"])) : ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_submenu, {
        key: 1,
        menu: item
      }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */)) : ((0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, {
        key: 1
      }, [item.children === undefined || !item.children.length ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Tooltip, {
        content: _ctx.tTitle(item.title),
        placement: "right",
        key: index,
        theme: _ctx.siderTheme
      }, {
        default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side_item, {
          class: "i-layout-menu-side-collapse-top-item",
          menu: item,
          "hide-title": ""
        }, null, 8 /* PROPS */, ["menu"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content", "theme"])) : ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_collapse, {
        key: 1,
        menu: item,
        "top-level": ""
      }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "theme", "accordion", "active-name", "open-names"])]);
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const meta = {
  title: '权限管理',
  auth: true
};
const pre = "auth-";
var _default = {
  path: "/auth",
  name: "authorityManagement",
  meta,
  component: () => import('@/pages/authority-management')
};
exports.default = _default;
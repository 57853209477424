"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueI18n = require("vue-i18n");
var _index = _interopRequireDefault(require("@/store/index"));
var _locale = _interopRequireDefault(require("./locale"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_index.default.dispatch('admin/i18n/getLocale');
const locale = _index.default.state.admin.i18n.locale;
var _default = (0, _vueI18n.createI18n)({
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  locale,
  messages: _locale.default
});
exports.default = _default;
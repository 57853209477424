"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  name: 'iHeaderLogo',
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['isMobile', 'headerTheme'])
  }
};
exports.default = _default;
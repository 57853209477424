"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.requestAnimation = requestAnimation;
var _util = _interopRequireDefault(require("./util.cookies"));
var _util2 = _interopRequireDefault(require("./util.log"));
var _util3 = _interopRequireDefault(require("./util.db"));
var _setting = _interopRequireDefault(require("@/setting"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const util = {
  cookies: _util.default,
  log: _util2.default,
  db: _util3.default
};
function tTitle(title = '') {
  const $t = window ? window.$app.$t : null;
  if ($t) {
    if (title.indexOf('$t:') === 0) {
      return $t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function ({
  title,
  count
}) {
  title = tTitle(title);
  let fullTitle;
  if (_setting.default.titleSuffix === false || _setting.default.titleSuffix === '') {
    fullTitle = title ? `${title}` : '';
  } else {
    fullTitle = title ? `${title} - ${_setting.default.titleSuffix}` : _setting.default.titleSuffix;
  }
  if (count) fullTitle = `(${count}条消息)${fullTitle}`;
  window.document.title = fullTitle;
};
function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }
  setTimeout(task, 16);
}
var _default = util;
exports.default = _default;
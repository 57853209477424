"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _withScopeId = n => ((0, _vue.pushScopeId)("data-v-5fab7447"), n = n(), (0, _vue.popScopeId)(), n);
const _hoisted_1 = {
  class: "i-layout-header-trigger i-layout-header-trigger-min"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_surname_avatar = (0, _vue.resolveComponent)("surname-avatar");
  const _component_Icon = (0, _vue.resolveComponent)("Icon");
  const _component_DropdownItem = (0, _vue.resolveComponent)("DropdownItem");
  const _component_DropdownMenu = (0, _vue.resolveComponent)("DropdownMenu");
  const _component_Dropdown = (0, _vue.resolveComponent)("Dropdown");
  return (0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Dropdown, {
    trigger: _ctx.isMobile ? 'click' : 'hover',
    class: (0, _vue.normalizeClass)(["i-layout-header-user", {
      'i-layout-header-user-mobile': _ctx.isMobile
    }]),
    onOnClick: $options.handleClick
  }, {
    list: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_DropdownMenu, null, {
      default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_DropdownItem, {
        name: "logout"
      }, {
        default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_Icon, {
          type: "ios-log-out"
        }), (0, _vue.createElementVNode)("span", null, (0, _vue.toDisplayString)(_ctx.$t('basicLayout.user.logOut')), 1 /* TEXT */)]),

        _: 1 /* STABLE */
      })]),

      _: 1 /* STABLE */
    })]),

    default: (0, _vue.withCtx)(() => [(0, _vue.createElementVNode)("span", _hoisted_1, [!_ctx.isMobile ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_surname_avatar, {
      key: 0,
      userInfo: $data.userInfo
    }, null, 8 /* PROPS */, ["userInfo"])) : (0, _vue.createCommentVNode)("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["trigger", "class", "onOnClick"]);
}
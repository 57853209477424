"use strict";

var _vue = require("vue");
var _App = _interopRequireDefault(require("@/App"));
var _setting = _interopRequireDefault(require("@/setting"));
var _app = _interopRequireDefault(require("@/mixins/app"));
var _plugins = _interopRequireDefault(require("@/plugins"));
var _index = _interopRequireDefault(require("@/store/index"));
var _viewUiPlus = _interopRequireDefault(require("view-ui-plus"));
var _router = _interopRequireDefault(require("@/router"));
var _header = _interopRequireDefault(require("@/menu/header"));
var _routes = require("@/router/routes");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _locale = require("@/i18n/locale");
var _link = _interopRequireDefault(require("@/components/link"));
var _frame = _interopRequireDefault(require("@/components/frame"));
require("./styles/index.less");
require("@/assets/iconfont/iconfont.css");
var _loading = _interopRequireDefault(require("@/common/directive/loading.js"));
var _vcolorpicker = _interopRequireDefault(require("vcolorpicker"));
var _vueKonva = _interopRequireDefault(require("vue-konva"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// Vue

// 配置

// 混合

// 插件

// store

// ViewUIPlus

// 菜单和路由

// 多语言

// 内置组件

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 View UI Plus 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme

//颜色选择器

// 百度统计
var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  if (process.env.NODE_ENV == 'production') {
    hm.src = "https://hm.baidu.com/hm.js?5d2719f959a22639b549be8758a6584b";
  } else {
    hm.src = "https://hm.baidu.com/hm.js?8bc69d0f80d876e24c1214af3f25d6fd";
  }
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

// 第一次进入时，取菜单数据用于更新
let isFirstRouteChange = true;
const app = (0, _vue.createApp)({
  mixins: [_app.default],
  render: () => (0, _vue.h)(_App.default),
  created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', _routes.frameInRoutes);
    // 设置顶栏菜单
    if (!_setting.default.dynamicMenu) this.$store.commit('admin/menu/setHeader', _header.default);
    // 加载用户登录的数据
    this.$store.dispatch('admin/account/load');
    // 初始化全屏监听
    this.$store.dispatch('admin/layout/listenFullscreen');
    // 将根实例存全局，可在特殊场景下调用
    if (window) {
      window.$app = (0, _vue.getCurrentInstance)();
      window.$app.$t = _i18n.default.global.t;
    }
    // 加载远程 i18n
    if (_setting.default.i18n.remote) (0, _locale.loadRemoteLocaleMessages)(_i18n.default);
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route'(to, from) {
      this.$store.dispatch('admin/menu/setMenuList', to);
      if (_setting.default.dynamicMenu) {
        if (isFirstRouteChange) {
          isFirstRouteChange = false;
          // 每次进入 app 时，获取一次菜单
          this.$store.dispatch('admin/menu/getMenuList', to);
        }
      }
      this.appRouteChange(to, from);
    }
  }
});
app.use(_router.default);
app.use(_index.default);
app.use(_i18n.default);
app.use(_viewUiPlus.default, {
  i18n: _i18n.default
});
app.use(_plugins.default);
app.use(_vcolorpicker.default);
app.use(_vueKonva.default);
app.component('i-link', _link.default);
app.component('i-frame', _frame.default);
app.directive(_loading.default.name, _loading.default.options);
app.mount('#app');
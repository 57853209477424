"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lowdb = _interopRequireDefault(require("lowdb"));
var _LocalStorage = _interopRequireDefault(require("lowdb/adapters/LocalStorage"));
var _setting = _interopRequireDefault(require("@/setting"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const adapter = new _LocalStorage.default(`admin-plus-${_setting.default.appID}`);
const db = (0, _lowdb.default)(adapter);
db.defaults({
  sys: {},
  database: {}
}).write();
var _default = db;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    show: Boolean,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invalidEmails: [],
      timeDate: [],
      formValidate: {
        brand_name: "",
        project_name: "",
        timeDate: [],
        usage: "",
        members: ""
      }
    };
  },
  methods: {
    onVisibleChange(e) {
      if (e) {
        this.$emit("update:show", true);
      } else {
        this.$emit("update:show", false);
      }
    },
    cancel() {
      this.$emit("update:show", false);
    },
    submit() {
      if (!this.formValidate.brand_name) {
        this.$Message.warning('请输入品牌名称');
        return;
      }
      if (!this.formValidate.project_name) {
        this.$Message.warning('请输入项目名称');
        return;
      }
      if (!this.formValidate.timeDate[0]) {
        this.$Message.warning('请选择项目期限');
        return;
      }
      if (!this.formValidate.usage) {
        this.$Message.warning('请输入项目需求');
        return;
      }
      if (!this.formValidate.members) {
        this.$Message.warning('请输入项目成员');
        return;
      }
      this.$emit("submit", this.formValidate);
    },
    dateFormat(e) {
      const startTime = new Date(e[0]);
      startTime.setHours(0, 0, 0, 0); // 设置开始日期的00:00:00
      const endTime = new Date(e[1]);
      endTime.setHours(23, 59, 59); // 设置结束日期的23:59:59:999

      this.formValidate.timeDate = [startTime.getTime() / 1000, endTime.getTime() / 1000];
    },
    // 邮箱格式
    validateEmails() {
      const emails = this.formValidate.members.split("\n").map(email => email.trim());
      const isValidEmail = email => {
        // 使用简单的正则表达式来验证邮箱格式
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      this.invalidEmails = emails.filter(email => email && !isValidEmail(email));
    }
  }
};
exports.default = _default;
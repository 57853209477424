"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIntroduction = getIntroduction;
exports.getNotice = getNotice;
exports.readNotice = readNotice;
var _request = _interopRequireDefault(require("@/plugins/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getNotice() {
  return (0, _request.default)({
    url: '/api/welcome/notice',
    method: 'get'
  });
}
function readNotice() {
  return (0, _request.default)({
    url: '/api/welcome/notice',
    method: 'put'
  });
}
function getIntroduction() {
  return (0, _request.default)({
    url: '/api/welcome/introduction',
    method: 'get'
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", {
    class: "loading-container row center",
    onClick: _cache[0] || (_cache[0] = (0, _vue.withModifiers)(() => {}, ["stop"]))
  }, [(0, _vue.createVNode)($setup["Icon"], {
    type: "ios-loading",
    class: "loading-icon",
    size: "42"
  })]);
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _image = _interopRequireDefault(require("@/assets/data/image.js"));
var _imageToolViewer = _interopRequireDefault(require("@/components/imageToolViewer"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const img = require('@/assets/images/layout/icon_down.png');
    const store = (0, _vuex.useStore)();
    const imageData = _image.default;
    const showDropdown = (0, _vue.ref)(false);
    const setShowDropdown = bool => {
      showDropdown.value = bool;
    };
    const setTab = tab => {
      store.commit('setImageTab', tab);
    };
    const __returned__ = {
      img,
      store,
      imageData,
      showDropdown,
      setShowDropdown,
      setTab,
      ref: _vue.ref,
      get data() {
        return _image.default;
      },
      get imageToolViewer() {
        return _imageToolViewer.default;
      },
      get useStore() {
        return _vuex.useStore;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
exports.default = _default;
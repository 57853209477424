"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 菜单，顶栏
var _default = [{
  path: '/',
  title: '首页',
  icon: 'md-home',
  hideSider: false,
  name: 'home'
}, {
  path: '/log',
  title: '日志',
  icon: 'md-locate',
  hideSider: true,
  name: 'system'
}];
exports.default = _default;
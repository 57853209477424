"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _loading = _interopRequireDefault(require("../components/loading"));
var _vue = require("vue");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//自定义的loading展示的组件，可以自己diy

// 判断是否显示loading
const judgeLoading = (container, binding) => {
  const setLoading = container => {
    const curDomStyle = getComputedStyle(container);
    container.setAttribute('oldPosition', curDomStyle.position);
    if (!['absolute', 'fixed', 'relative'].includes(curDomStyle.position)) {
      container.style.position = 'relative';
    }
    container.appendChild(container.loadingInstance.$el);
  };
  const removeLoading = container => {
    try {
      const loadingInstanceEl = container.loadingInstance.$el;
      if (loadingInstanceEl && loadingInstanceEl.parentNode == container) {
        container.removeChild(loadingInstanceEl);
        container.style.position = container.getAttribute('oldPosition') || '';
      }
    } catch (error) {
      console.warn(error);
    }
  };
  if (binding.value) {
    setLoading(container);
  } else {
    removeLoading(container);
  }
};
const loading = {
  name: 'loading',
  options: {
    loadingInstance: null,
    mounted(el, binding) {
      const app = (0, _vue.createApp)(_loading.default);
      const loadingInstance = app.mount(document.createElement('div'));
      el.loadingInstance = loadingInstance;
      judgeLoading(el, binding);
    },
    updated(el, binding) {
      judgeLoading(el, binding);
    }
  }
};
var _default = loading;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _setting = _interopRequireDefault(require("@/setting"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const cookies = {};

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} cookieSetting cookie setting
 */
cookies.set = function (name = 'default', value = '', cookieSetting = {}) {
  let currentCookieSetting = {};
  if (_setting.default.cookiesExpires !== 0) currentCookieSetting.expires = _setting.default.cookiesExpires;
  Object.assign(currentCookieSetting, cookieSetting);
  _jsCookie.default.set(`admin-plus-${_setting.default.appID}-${name}`, value, currentCookieSetting);
};

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
  return _jsCookie.default.get(`admin-plus-${_setting.default.appID}-${name}`);
};

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return _jsCookie.default.get();
};

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = 'default') {
  return _jsCookie.default.remove(`admin-plus-${_setting.default.appID}-${name}`);
};
var _default = cookies;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * 通用混合
 * */
var _default = {
  methods: {
    // 当 $route 更新时触发
    appRouteChange(to, from) {}
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
var _side_newIcon = _interopRequireDefault(require("@/assets/images/side_newIcon.png"));
var _side_updateIcon = _interopRequireDefault(require("@/assets/images/side_updateIcon.png"));
var _test_icon = _interopRequireDefault(require("@/assets/images/test_icon.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _hoisted_1 = {
  key: 0,
  class: "menu-item"
};
const _hoisted_2 = {
  key: 1,
  src: _side_newIcon.default,
  alt: "",
  style: {
    "width": "22px",
    "height": "11px",
    "margin-left": "6px",
    "vertical-align": "top"
  }
};
const _hoisted_3 = {
  key: 2,
  src: _side_updateIcon.default,
  alt: "",
  style: {
    "width": "35px",
    "height": "11px",
    "margin-left": "6px",
    "vertical-align": "top"
  }
};
const _hoisted_4 = {
  key: 3,
  src: _test_icon.default,
  alt: "",
  style: {
    "width": "35px",
    "height": "11px",
    "object-fit": "contain",
    "vertical-align": "top"
  }
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = (0, _vue.resolveComponent)("i-menu-side-title");
  const _component_Badge = (0, _vue.resolveComponent)("Badge");
  const _component_MenuItem = (0, _vue.resolveComponent)("MenuItem", true);
  return ($props.menu.checkDisPlay ? $props.menu.checkDisPlay() : true) ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_1, [(0, _vue.createVNode)(_component_MenuItem, (0, _vue.mergeProps)(_ctx.$attrs, {
    to: $props.menu.path,
    replace: $props.menu.replace,
    target: $props.menu.target,
    name: $props.menu.path,
    onClick: _cache[0] || (_cache[0] = $event => $options.customClick($props.menu))
  }), {
    default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side_title, {
      menu: $props.menu,
      "hide-title": $props.hideTitle
    }, null, 8 /* PROPS */, ["menu", "hide-title"]), _ctx.badgeData ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Badge, (0, _vue.mergeProps)({
      key: 0,
      class: "i-layout-menu-side-badge"
    }, _ctx.badgeData), null, 16 /* FULL_PROPS */)) : (0, _vue.createCommentVNode)("v-if", true), $props.menu.isNew && !$props.hideTitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_2)) : (0, _vue.createCommentVNode)("v-if", true), $props.menu.isUpdate && !$props.hideTitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_3)) : (0, _vue.createCommentVNode)("v-if", true), $props.menu.isTest && !$props.hideTitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_4)) : (0, _vue.createCommentVNode)("v-if", true)]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["to", "replace", "target", "name"])])) : (0, _vue.createCommentVNode)("v-if", true);
}
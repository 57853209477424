"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uncrop = _interopRequireDefault(require("@/assets/video/uncrop.mp4"));
var _generate = _interopRequireDefault(require("@/assets/video/generate.mp4"));
var _change_style = _interopRequireDefault(require("@/assets/video/change_style.mp4"));
var _reimagine = _interopRequireDefault(require("@/assets/video/reimagine.mp4"));
var _replace_bg = _interopRequireDefault(require("@/assets/video/replace_bg.mp4"));
var _remove_text = _interopRequireDefault(require("@/assets/video/remove_text.mp4"));
var _upscaler = _interopRequireDefault(require("@/assets/video/upscaler.mp4"));
var _cleanup = _interopRequireDefault(require("@/assets/video/cleanup.mp4"));
var _qrcode = _interopRequireDefault(require("@/assets/video/qrcode.mp4"));
var _icon_generate = _interopRequireDefault(require("@/assets/images/image/icon_generate.png"));
var _icon_uncrop = _interopRequireDefault(require("@/assets/images/image/icon_uncrop.png"));
var _icon_cleanup = _interopRequireDefault(require("@/assets/images/image/icon_cleanup.png"));
var _icon_upscaler = _interopRequireDefault(require("@/assets/images/image/icon_upscaler.png"));
var _icon_replace_bg = _interopRequireDefault(require("@/assets/images/image/icon_replace_bg.png"));
var _icon_reimagine = _interopRequireDefault(require("@/assets/images/image/icon_reimagine.png"));
var _icon_change_style = _interopRequireDefault(require("@/assets/images/image/icon_change_style.png"));
var _icon_remove_text = _interopRequireDefault(require("@/assets/images/image/icon_remove_text.png"));
var _icon_qrcode = _interopRequireDefault(require("@/assets/images/image/icon_qrcode.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  title: '图片创作工具箱',
  home: {
    title: '查看全部工具'
  },
  list: [{
    video: _generate.default,
    icon: _icon_generate.default,
    title: '文生图',
    desc: '描述一句话，生成一副美丽的图片'
  }, {
    video: _replace_bg.default,
    icon: _icon_replace_bg.default,
    title: '背景替换',
    desc: '快速选择主体，替换背景'
  },
  // {
  //     video: video_qrcode,
  //     icon: icon_qrcode,
  //     title: '创意融合',
  //     desc: '创意融合二维码、字体和品牌logo',
  // },
  {
    video: _reimagine.default,
    icon: _icon_reimagine.default,
    title: '图像重构',
    desc: '上传图片并对输出的图片再次重新生成'
  }, {
    video: _cleanup.default,
    icon: _icon_cleanup.default,
    title: '污点修复',
    desc: '快速从图片中删除物体、缺陷、人物或文本'
  },
  // {
  //     video: video_uncrop,
  //     icon: icon_uncrop,
  //     isNew: true,
  //     title: '场景扩充',
  //     desc: '一键抠图，场景生成，帮你延伸扩展场景',
  // },
  {
    video: _upscaler.default,
    icon: _icon_upscaler.default,
    title: '清晰度增强',
    desc: '去除噪点，使图片变清晰'
  }, {
    video: _remove_text.default,
    icon: _icon_remove_text.default,
    title: '文字擦除',
    desc: '上传图片，去除图片中所有文字'
  }, {
    video: _change_style.default,
    icon: _icon_change_style.default,
    title: '风格迁移',
    desc: '将上传图片按照指定的风格进行风格的迁移'
  }]
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const meta = {
  title: '图片创作',
  auth: true
};
var _default = [{
  path: "/image",
  name: "imagesWriting",
  meta,
  component: () => import('@/pages/images-writing')
}, {
  path: "/image/generate",
  name: "imagesWritingGenerate",
  meta,
  component: () => import('@/pages/images-writing/generate.vue')
}];
exports.default = _default;
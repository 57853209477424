"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const pre = '/image/';
var _default = [{
  path: '/image',
  title: '图片创作',
  header: 'home',
  custom: 'iconfont icon-image'
}];
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _surnameAvatar = _interopRequireDefault(require("@/components/surnameAvatar"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'iHeaderUser',
  components: {
    SurnameAvatar: _surnameAvatar.default
  },
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['isMobile', 'logoutConfirm'])
  },
  mounted() {
    this.userInfo = JSON.parse(window.localStorage.getItem('user_info'));
  },
  data() {
    return {
      userInfo: ''
    };
  },
  methods: {
    handleClick(name) {
      if (name === 'logout') {
        this.$store.dispatch('user/logout');
      }
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  path: '/model',
  title: '三维模型',
  header: 'home',
  custom: 'iconfont icon-model'
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountRegister = AccountRegister;
exports.addProfileGroup = addProfileGroup;
exports.assignScore = assignScore;
exports.getAllGroupsList = getAllGroupsList;
exports.getGroupDetail = getGroupDetail;
exports.getGroupRequests = getGroupRequests;
exports.getProfileInfo = getProfileInfo;
exports.getRequestGroups = getRequestGroups;
exports.getScoreRecords = getScoreRecords;
exports.getSearchUsers = getSearchUsers;
exports.getUsers = getUsers;
exports.operateGroupRequest = operateGroupRequest;
exports.switchGroup = switchGroup;
exports.updateGroupInfo = updateGroupInfo;
var _request = _interopRequireDefault(require("@/plugins/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function AccountRegister(data) {
  return (0, _request.default)({
    url: "/api/register",
    method: "post",
    data
  });
}

// 获取当前用户信息
function getProfileInfo() {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/profile",
    method: "GET"
  });
}

// 切换所在组
function switchGroup(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/profile/group-switch",
    method: "POST",
    data
  });
}

// 申请创建用户组
function addProfileGroup(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/profile/group-request",
    method: "post",
    data
  });
}

// 获取当前用户所有创建组请求
function getGroupRequests() {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/profile/group-request",
    method: "GET"
  });
}

// 操作组请求 同意/拒绝
function operateGroupRequest(data, group_request_id) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: `/api/system/group-request/${group_request_id}`,
    method: "post",
    data
  });
}

// 获取组列表
function getAllGroupsList() {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/group/list",
    method: "GET"
  });
}

// 分配积分
function assignScore(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: `/api/system/score/assign`,
    method: "post",
    data
  });
}

// 获取组详情
function getGroupDetail(group_id) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: `/api/system/group/${group_id}`,
    method: "GET"
  });
}

// 更新组信息 添加/移除成员
function updateGroupInfo(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: `/api/system/group/${data.group_id}`,
    method: "post",
    data
  });
}

// 获取组请求列表
function getRequestGroups() {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/system/group-request/list",
    method: "GET"
  });
}

/**
 * {
 * target: '', 用户邮箱或组id
 * target_type: '', 消耗主体， user 或 group
 * }
 */
function getScoreRecords(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: `/api/system/score/log`,
    method: "post",
    data
  });
}

// 获取用户列表
function getUsers(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/authority/get_users/v3",
    method: "post",
    data
  });
}

// 用户列表搜索
function getSearchUsers(data) {
  return (0, _request.default)({
    requestBase: "WPP_API",
    url: "/api/authority/query/v3",
    method: "post",
    data
  });
}
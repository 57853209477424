"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const pre = '/audio/';
var _default = {
  path: '/audio',
  title: '音频创作',
  header: 'home',
  custom: 'iconfont icon-audio'
};
exports.default = _default;
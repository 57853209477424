"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _link = _interopRequireDefault(require("view-ui-plus/src/mixins/link"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'i-link',
  mixins: [_link.default],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    // 开启后，链接颜色为默认的蓝色，默认关闭为继承效果
    linkColor: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClickItem(event, new_window = false) {
      if (this.disabled) {
        event.preventDefault();
        return;
      }
      this.handleCheckClick(event, new_window);
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = (0, _vue.resolveComponent)("i-menu-side-title");
  const _component_Badge = (0, _vue.resolveComponent)("Badge");
  const _component_i_menu_side_item = (0, _vue.resolveComponent)("i-menu-side-item");
  const _component_i_menu_side_submenu = (0, _vue.resolveComponent)("i-menu-side-submenu");
  const _component_Submenu = (0, _vue.resolveComponent)("Submenu", true);
  return (0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Submenu, {
    name: $props.menu.path
  }, {
    title: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side_title, {
      menu: $props.menu
    }, null, 8 /* PROPS */, ["menu"]), _ctx.badgeData ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Badge, (0, _vue.mergeProps)({
      key: 0,
      class: "i-layout-menu-side-badge"
    }, _ctx.badgeData), null, 16 /* FULL_PROPS */)) : (0, _vue.createCommentVNode)("v-if", true)]),
    default: (0, _vue.withCtx)(() => [((0, _vue.openBlock)(true), (0, _vue.createElementBlock)(_vue.Fragment, null, (0, _vue.renderList)($props.menu.children, (item, index) => {
      return (0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, {
        key: index
      }, [item.children === undefined || !item.children.length ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_item, {
        menu: item,
        key: index
      }, null, 8 /* PROPS */, ["menu"])) : ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_submenu, {
        key: 1,
        menu: item
      }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["name"]);
}
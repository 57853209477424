"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _menuTitle = _interopRequireDefault(require("./menu-title"));
var _clickItem = _interopRequireDefault(require("../mixins/click-item"));
var _setting = _interopRequireDefault(require("@/setting"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'iMenuSideCollapse',
  components: {
    iMenuSideTitle: _menuTitle.default
  },
  mixins: [_clickItem.default],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否是第一级，区分在于左侧和展开侧
    topLevel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['siderTheme', 'showCollapseMenuTitle', 'bodyHeight']),
    ...(0, _vuex.mapState)('admin/menu', ['activePath', 'openNames']),
    dropdownClasses() {
      return {
        'i-layout-menu-side-collapse-top': this.topLevel,
        'i-layout-menu-side-collapse-dark': this.siderTheme === 'dark'
      };
    },
    menuItemClasses() {
      return ['ivu-menu-item i-layout-menu-side-collapse-top-item', {
        'ivu-menu-item-selected ivu-menu-item-active': this.openNames.indexOf(this.menu.path) >= 0 // -active 在高亮时，有背景
      }];
    },

    // 菜单过多时，限高滚动
    dropdownMenuMaxHeight() {
      return {
        'max-height': `calc(${this.bodyHeight}px - ${_setting.default.headerHeight}px - 32px)`
      };
    }
  }
};
exports.default = _default;
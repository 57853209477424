"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _hoisted_1 = ["href", "target"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("a", {
    href: _ctx.linkUrl,
    target: _ctx.target,
    class: (0, _vue.normalizeClass)(["i-link", {
      'i-link-color': !$props.linkColor
    }]),
    onClick: [_cache[0] || (_cache[0] = (0, _vue.withModifiers)($event => $options.handleClickItem($event, false), ["exact"])), _cache[1] || (_cache[1] = (0, _vue.withModifiers)($event => $options.handleClickItem($event, true), ["ctrl"])), _cache[2] || (_cache[2] = (0, _vue.withModifiers)($event => $options.handleClickItem($event, true), ["meta"]))]
  }, [(0, _vue.renderSlot)(_ctx.$slots, "default")], 10 /* CLASS, PROPS */, _hoisted_1);
}
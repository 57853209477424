"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _assist = require("view-ui-plus/src/utils/assist");
var _vuex = require("vuex");
var _default = {
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['menuSiderReload', 'menuHeaderReload'])
  },
  methods: {
    handleClick(path, type = 'sider') {
      const current = this.$route.path;
      if (current === path) {
        if (type === 'sider' && this.menuSiderReload) this.handleReload();else if (type === 'header' && this.menuHeaderReload) this.handleReload();
      }
    },
    handleReload() {
      const $layout = (0, _assist.findComponentUpward)(this, 'BasicLayout');
      if ($layout) $layout.handleReload();
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _vuex = require("vuex");
var _setting = _interopRequireDefault(require("@/setting"));
var _menuItem = _interopRequireDefault(require("./menu-item"));
var _submenu = _interopRequireDefault(require("./submenu"));
var _menuCollapse = _interopRequireDefault(require("./menu-collapse"));
var _translateTitle = _interopRequireDefault(require("../mixins/translate-title"));
var _sider = _interopRequireDefault(require("@/menu/sider"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 元素是否在可视区域
function isElementInViewport(el) {
  let rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}
var _default = {
  name: 'iMenuSide',
  mixins: [_translateTitle.default],
  components: {
    iMenuSideItem: _menuItem.default,
    iMenuSideSubmenu: _submenu.default,
    iMenuSideCollapse: _menuCollapse.default
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse']),
    ...(0, _vuex.mapState)('admin/menu', ['activePath', 'openNames']),
    ...(0, _vuex.mapGetters)('admin/menu', ['filterSider'])
  },
  data() {
    return {
      menuSider: _sider.default
    };
  },
  watch: {
    '$route': {
      handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState() {
      (0, _vue.nextTick)(() => {
        if (this.$refs.menu) {
          this.$refs.menu.updateActiveName();
          if (this.menuAccordion) this.$refs.menu.updateOpened();
          // 聚焦当前项
          (0, _vue.nextTick)(() => {
            const $activeMenu = document.getElementsByClassName('ivu-menu-item ivu-menu-item-active ivu-menu-item-selected');
            if ($activeMenu && $activeMenu.length && !isElementInViewport($activeMenu[0])) {
              const activeMenuTop = $activeMenu[0].getBoundingClientRect().top - _setting.default.headerHeight;
              const $menu = this.$refs.menu.$el;
              setTimeout(() => {
                this.$ScrollTop($menu, {
                  to: activeMenuTop,
                  time: 0
                });
              }, 300);
            }
          });
        }
      });
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _loginToken = require("@/api/loginToken");
var _auth = require("@/api/auth");
var _setting = _interopRequireDefault(require("@/setting"));
var _router = _interopRequireDefault(require("@/router"));
var _viewUiPlus = require("view-ui-plus");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    /**
     * 清除用户信息
     */
    clear() {
      localStorage.clear();
    },
    login(context, {
      code
    }) {
      return new Promise((resolve, reject) => {
        (0, _loginToken.getToken)({
          code
        }).then(async res => {
          if (res.code === 20001) {
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('id_token', res.data.id_token);
          } else {
            _viewUiPlus.Message.warning('token code已被使用过,请重新登录');
            context.dispatch('clear');
            _router.default.push('/login');
          }
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    getUserInfo(context) {
      return new Promise((resolve, reject) => {
        (0, _loginToken.getUserInfo)({
          id_token: localStorage.getItem('id_token')
        }).then(async res => {
          if (res.code === 20001) {
            localStorage.setItem('user_info', JSON.stringify(res.data));
          }
          if (_setting.default.dynamicMenu) context.dispatch('admin/menu/getMenuList', false, {
            root: true
          });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    verifyUsers(context) {
      return new Promise((resolve, reject) => {
        (0, _auth.verifyUsers)({
          token: localStorage.getItem('access_token')
        }).then(async res => {
          if (res.code === 10000) {
            localStorage.setItem('has_access', res.payload[0].has_access);
            localStorage.setItem('is_manager', res.payload[0].is_manager);
            if (localStorage.getItem('has_access') === 'false') {
              _router.default.push({
                path: 'error'
              });
              return;
            }
          } else if (res.code === 9008) {
            _viewUiPlus.Message.warning('token已过期，请重新登录');
            context.dispatch('clear');
            _router.default.push({
              path: 'login'
            });
          } else if (res.code == 9010) {
            _router.default.push({
              path: 'error'
            });
          }
          if (_setting.default.dynamicMenu) context.dispatch('admin/menu/getMenuList', false, {
            root: true
          });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    logout(context) {
      return new Promise(() => {
        let idToken = localStorage.getItem('id_token');
        context.dispatch('clear');
        window.location.href = `${process.env.VUE_APP_BASE_LOGIN_URL}/wppsso/v1/oauth2/logout?client_id=${process.env.VUE_APP_BASE_CLIENTID}&post_logout_redirect_uri=${process.env.VUE_APP_BASE_API}/&id_token_hint=${idToken}`;
      });
    }
  }
};
exports.default = _default;
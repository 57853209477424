"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _error = _interopRequireDefault(require("@/plugins/error"));
var _log = _interopRequireDefault(require("@/plugins/log"));
var _auth = _interopRequireDefault(require("@/plugins/auth"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 插件
 * */
// 错误捕获
// 日志插件
// 鉴权指令
var _default = {
  async install(app, options) {
    // 插件
    app.use(_error.default);
    app.use(_log.default);

    // 指令
    app.directive('auth', _auth.default);
  }
};
exports.default = _default;
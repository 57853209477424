"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basicLayout = _interopRequireDefault(require("@/layouts/basic-layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const meta = {
  title: '音频创作',
  auth: true
};
const pre = "audio-";
var _default = {
  path: "/audio",
  name: "audioWriting",
  meta,
  component: () => import('@/pages/audio-writing')
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  path: '/demo',
  title: '知识库',
  header: 'home',
  custom: 'iconfont icon-a-bianzu57',
  checkDisPlay: () => {
    return localStorage.getItem('is_manager') === 'true';
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _screenfull = _interopRequireDefault(require("screenfull"));
var _setting = _interopRequireDefault(require("@/setting"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 布局配置
 * */
var _default = {
  namespaced: true,
  state: {
    ..._setting.default.layout,
    isMobile: false,
    // 是否为手机
    isTablet: false,
    // 是否为平板
    isDesktop: true,
    // 是否为桌面
    isFullscreen: false,
    // 是否切换到了全屏
    bodyHeight: 0,
    waterMark: _setting.default.waterMark
  },
  mutations: {
    /**
     * @description 设置设备类型
     * @param {Object} state vuex state
     * @param {String} type 设备类型，可选值为 Mobile、Tablet、Desktop
     */
    setDevice(state, type) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = false;
      state[`is${type}`] = true;
    },
    /**
     * @description 修改 menuCollapse
     * @param {Object} state vuex state
     * @param {Boolean} collapse 折叠状态
     * */
    updateMenuCollapse(state, collapse) {
      state.menuCollapse = collapse;
    },
    /**
     * @description 设置全屏状态
     * @param {Object} state vuex state
     * @param {Boolean} isFullscreen vuex
     * */
    setFullscreen(state, isFullscreen) {
      state.isFullscreen = isFullscreen;
    },
    /**
     * @description 更改指定布局配置
     * @param {Object} state vuex state
     * @param {Object} key layout 名称，对应 Setting.layout
     * @param {Object} value layout 值
     * */
    updateLayoutSetting(state, {
      key,
      value
    }) {
      state[key] = value;
    },
    /**
     * @description 更新页面高度
     * */
    setBodyHeight(state, height) {
      state.bodyHeight = height;
    },
    /**
     * @description 更新水印显示状态
     * */
    updateWaterMarkStatus(state, status) {
      state.waterMark.show = status;
    },
    /**
     * @description 更新水印文本
     * */
    updateWaterMarkText(state, text) {
      state.waterMark.text = text;
    },
    /**
     * @description 更新水印配置
     * */
    updateWaterMarkOptions(state, options) {
      state.waterMark.options = options;
    }
  },
  actions: {
    /**
     * @description 初始化监听全屏状态
     */
    listenFullscreen({
      commit
    }) {
      return new Promise(resolve => {
        if (_screenfull.default.enabled) {
          _screenfull.default.on('change', () => {
            if (!_screenfull.default.isFullscreen) {
              commit('setFullscreen', false);
            }
          });
        }
        // end
        resolve();
      });
    },
    /**
     * @description 切换全屏
     */
    toggleFullscreen({
      commit
    }) {
      return new Promise(resolve => {
        if (_screenfull.default.isFullscreen) {
          _screenfull.default.exit();
          commit('setFullscreen', false);
        } else {
          _screenfull.default.request();
          commit('setFullscreen', true);
        }
        // end
        resolve();
      });
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _viewUiPlus = require("view-ui-plus");
var _default = {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    expose();
    const __returned__ = {
      get Icon() {
        return _viewUiPlus.Icon;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _hoisted_1 = {
  id: "app"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = (0, _vue.resolveComponent)("router-view");
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_1, [(0, _vue.createVNode)(_component_router_view)]);
}
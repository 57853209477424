"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _util = _interopRequireDefault(require("@/libs/util"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  install(app, options) {
    // 快速打印 log
    app.config.globalProperties.$log = {
      ..._util.default.log,
      push(data) {
        if (typeof data === 'string') {
          // 如果传递来的数据是字符串
          // 赋值给 message 字段
          // 为了方便使用
          // eg: this.$log.push('foo text')
          _store.default.dispatch('admin/log/push', {
            message: data
          });
        } else if (typeof data === 'object') {
          // 如果传递来的数据是对象
          _store.default.dispatch('admin/log/push', data);
        }
      }
    };
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  path: "/text",
  title: "文案创作",
  header: "home",
  custom: 'iconfont icon-text'
};
exports.default = _default;
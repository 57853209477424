"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
var _news_icon = _interopRequireDefault(require("@/assets/images/news_icon1.png"));
var _beta_icon = _interopRequireDefault(require("@/assets/images/beta_icon.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _withScopeId = n => ((0, _vue.pushScopeId)("data-v-2469e06a"), n = n(), (0, _vue.popScopeId)(), n);
const _hoisted_1 = {
  class: "tip row ai-center jc-between"
};
const _hoisted_2 = {
  class: "column"
};
const _hoisted_3 = {
  style: {
    "flex": "1",
    "display": "inline-flex"
  }
};
const _hoisted_4 = {
  key: 0,
  class: "menu-list"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  class: "menu-item_img",
  src: _news_icon.default,
  alt: ""
};
const _hoisted_7 = {
  key: 1,
  class: "menu-item_img",
  src: _beta_icon.default,
  alt: ""
};
const _hoisted_8 = {
  class: "i-layout-header-right"
};
const _hoisted_9 = {
  class: "i-layout-content-main"
};
const _hoisted_10 = {
  key: 1
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = (0, _vue.resolveComponent)("Icon");
  const _component_i_menu_side = (0, _vue.resolveComponent)("i-menu-side");
  const _component_Sider = (0, _vue.resolveComponent)("Sider");
  const _component_i_header_logo = (0, _vue.resolveComponent)("i-header-logo");
  const _component_imageDropdown = (0, _vue.resolveComponent)("imageDropdown");
  const _component_i_header_account = (0, _vue.resolveComponent)("i-header-account");
  const _component_i_header_user = (0, _vue.resolveComponent)("i-header-user");
  const _component_Header = (0, _vue.resolveComponent)("Header");
  const _component_router_view = (0, _vue.resolveComponent)("router-view");
  const _component_Content = (0, _vue.resolveComponent)("Content");
  const _component_Layout = (0, _vue.resolveComponent)("Layout");
  const _component_Drawer = (0, _vue.resolveComponent)("Drawer");
  const _component_i_water_mark = (0, _vue.resolveComponent)("i-water-mark");
  const _directive_resize = (0, _vue.resolveDirective)("resize");
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, null, [(0, _vue.createElementVNode)("div", _hoisted_1, [(0, _vue.createElementVNode)("div", null, (0, _vue.toDisplayString)($data.tip), 1 /* TEXT */), (0, _vue.createVNode)(_component_Icon, {
    type: "md-close",
    onClick: $options.closeTip
  }, null, 8 /* PROPS */, ["onClick"])]), (0, _vue.createVNode)(_component_Layout, {
    class: (0, _vue.normalizeClass)(["i-layout", {
      'show-tip': $data.showTip,
      'page-no-sider': $options.pageNoSider
    }])
  }, {
    default: (0, _vue.withCtx)(() => [!_ctx.isMobile && !_ctx.hideSider ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Sider, {
      key: 0,
      class: (0, _vue.normalizeClass)(["i-layout-sider", $options.siderClasses]),
      width: $options.menuSideWidth,
      onMouseenter: $options.handleEnterSider,
      onMouseleave: $options.handleLeaveSider
    }, {
      default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side, {
        "hide-logo": $options.isHeaderStick && _ctx.headerFix && $options.showHeader
      }, null, 8 /* PROPS */, ["hide-logo"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "width", "onMouseenter", "onMouseleave"])) : (0, _vue.createCommentVNode)("v-if", true), (0, _vue.createVNode)(_component_Layout, {
      class: (0, _vue.normalizeClass)(["i-layout-inside", $options.insideClasses])
    }, {
      default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_vue.Transition, {
        name: "fade-quick"
      }, {
        default: (0, _vue.withCtx)(() => [(0, _vue.createElementVNode)("div", _hoisted_2, [(0, _vue.withDirectives)(((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Header, {
          class: (0, _vue.normalizeClass)(["i-layout-header", $options.headerClasses]),
          style: (0, _vue.normalizeStyle)($options.headerStyle)
        }, {
          default: (0, _vue.withCtx)(() => [_ctx.isMobile && _ctx.showMobileLogo ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_header_logo, {
            key: 0
          })) : (0, _vue.createCommentVNode)("v-if", true), !_ctx.isMobile && $options.isHeaderStick && _ctx.headerFix ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_header_logo, {
            key: 1
          })) : (0, _vue.createCommentVNode)("v-if", true), (0, _vue.createElementVNode)("div", _hoisted_3, [$data.menuList && $data.menuList.length ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_4, [((0, _vue.openBlock)(true), (0, _vue.createElementBlock)(_vue.Fragment, null, (0, _vue.renderList)($data.menuList, (item, index) => {
            return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("span", {
              class: (0, _vue.normalizeClass)(["menu-item", {
                'active': item.active
              }]),
              key: index,
              onClick: $event => $options.switchMenu(item)
            }, [(0, _vue.createTextVNode)((0, _vue.toDisplayString)(item.name) + " ", 1 /* TEXT */), item.news ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_6)) : (0, _vue.createCommentVNode)("v-if", true), item.betaIcon ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_7)) : (0, _vue.createCommentVNode)("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_5);
          }), 128 /* KEYED_FRAGMENT */))])) : (0, _vue.createCommentVNode)("v-if", true), $data.showImageDropdown ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_imageDropdown, {
            key: 1
          })) : (0, _vue.createCommentVNode)("v-if", true)]), (0, _vue.createElementVNode)("div", _hoisted_8, [(0, _vue.createVNode)(_component_i_header_account), (0, _vue.createVNode)(_component_i_header_user)])]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "style"])), [[_vue.vShow, $options.showHeader], [_directive_resize, $options.handleHeaderWidthChange]])])]),
        _: 1 /* STABLE */
      }), (0, _vue.createVNode)(_component_Content, {
        class: (0, _vue.normalizeClass)(["i-layout-content", $options.contentClasses])
      }, {
        default: (0, _vue.withCtx)(() => [(0, _vue.createElementVNode)("div", _hoisted_9, [(0, _vue.createVNode)(_component_router_view, null, {
          default: (0, _vue.withCtx)(({
            Component
          }) => [((0, _vue.openBlock)(), (0, _vue.createBlock)(_vue.KeepAlive, {
            include: _ctx.keepAlive
          }, [$data.loadRouter ? ((0, _vue.openBlock)(), (0, _vue.createBlock)((0, _vue.resolveDynamicComponent)(Component), {
            key: Component.name
          })) : (0, _vue.createCommentVNode)("v-if", true)], 1032 /* PROPS, DYNAMIC_SLOTS */, ["include"]))]),
          _: 1 /* STABLE */
        })])]),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]), _ctx.isMobile && !_ctx.hideSider ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_10, [(0, _vue.createVNode)(_component_Drawer, {
      modelValue: $data.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.showDrawer = $event),
      placement: "left",
      closable: false,
      "class-name": $options.drawerClasses
    }, {
      default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class-name"])])) : (0, _vue.createCommentVNode)("v-if", true), (0, _vue.createVNode)(_component_i_water_mark)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"])], 64 /* STABLE_FRAGMENT */);
}
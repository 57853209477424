"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _vuex = require("vuex");
var _menuSide = _interopRequireDefault(require("./menu-side"));
var _headerLogo = _interopRequireDefault(require("./header-logo"));
var _headerAccount = _interopRequireDefault(require("./header-account"));
var _headerUser = _interopRequireDefault(require("./header-user"));
var _waterMark = _interopRequireDefault(require("./water-mark"));
var _imageDropdown = _interopRequireDefault(require("@/components/imageDropdown"));
var _setting = _interopRequireDefault(require("@/setting"));
var _util = require("@/libs/util");
var _imageWriting = require("@/api/imageWriting");
var _home = require("@/api/home");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'BasicLayout',
  components: {
    iMenuSide: _menuSide.default,
    iHeaderLogo: _headerLogo.default,
    iHeaderUser: _headerUser.default,
    iWaterMark: _waterMark.default,
    imageDropdown: _imageDropdown.default,
    iHeaderAccount: _headerAccount.default
  },
  data() {
    return {
      showDrawer: false,
      ticking: false,
      headerVisible: true,
      oldScrollTop: 0,
      isDelayHideSider: false,
      // hack，当从隐藏侧边栏的 header 切换到正常 header 时，防止 Logo 抖动
      loadRouter: true,
      menuList: null,
      showImageDropdown: false,
      imgMenuList: [{
        name: '文生图',
        active: true
      }, {
        name: '定制模型',
        active: false
      }, {
        name: '风格迁移',
        active: false
      },
      // { name: '海报创作', active: false },
      {
        name: '创意融合',
        active: false,
        news: true
      }],
      audioMenuList: [
      // { name: '文生乐', active: true },
      {
        name: '语音合成',
        active: true
      }],
      creationMode: [{
        name: '自由创作',
        active: true
      }, {
        name: '场景创作',
        active: false
      }],
      pdfAiList: [{
        name: '知识库',
        active: true,
        betaIcon: true
      }, {
        name: '文档分析',
        active: false
      }],
      authMenuList: [{
        name: '文案管理',
        active: true
      }, {
        name: '组织管理',
        active: false
      }],
      threeDModelList: [{
        name: '文生3D',
        active: true
      }, {
        name: '我的3D',
        active: false
      }],
      switchStatus: false,
      showTip: false,
      tip: ''
    };
  },
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['siderTheme', 'headerTheme', 'headerStick', 'tabs', 'tabsFix', 'siderFix', 'headerFix', 'headerHide', 'headerMenu', 'isMobile', 'isTablet', 'isDesktop', 'menuCollapse', 'showMobileLogo', 'showSearch', 'showNotice', 'showFullscreen', 'showSiderCollapse', 'showBreadcrumb', 'showLog', 'showI18n', 'showReload', 'enableSetting']),
    ...(0, _vuex.mapState)('admin/page', ['keepAlive']),
    ...(0, _vuex.mapGetters)('admin/menu', ['hideSider']),
    ...(0, _vuex.mapState)(['imageTab', 'audioTab', 'textTab']),
    imageTab() {
      return this.$store.state.imageTab;
    },
    audioTab() {
      return this.$store.state.audioTab;
    },
    // 如果开启 headerMenu，且当前 header 的 hideSider 为 true，则将顶部按 headerStick 处理
    // 这时，即使没有开启 headerStick，仍然按开启处理
    isHeaderStick() {
      let state = this.headerStick;
      if (this.hideSider) state = true;
      return state;
    },
    showHeader() {
      let visible = true;
      if (this.headerFix && this.headerHide && !this.headerVisible) visible = false;
      return visible;
    },
    headerClasses() {
      return [`i-layout-header-color-${this.headerTheme}`, {
        'i-layout-header-fix': this.headerFix,
        'i-layout-header-fix-collapse': this.headerFix && this.menuCollapse,
        'i-layout-header-mobile': this.isMobile,
        'i-layout-header-stick': this.isHeaderStick && !this.isMobile,
        'i-layout-header-with-menu': this.headerMenu,
        'i-layout-header-with-hide-sider': this.hideSider || this.isDelayHideSider
      }];
    },
    headerStyle() {
      const menuWidth = this.isHeaderStick ? 0 : this.menuCollapse ? _setting.default.menuSideCollapseWidth : _setting.default.menuSideWidth;
      return this.isMobile || !this.headerFix ? {} : {
        width: `calc(100% - ${menuWidth}px)`
      };
    },
    siderClasses() {
      return {
        'i-layout-sider-fix': this.siderFix,
        'i-layout-sider-dark': this.siderTheme === 'dark'
      };
    },
    contentClasses() {
      return {
        'i-layout-content-fix-with-header': this.headerFix,
        'i-layout-content-with-tabs': this.tabs,
        'i-layout-content-with-tabs-fix': this.tabs && this.tabsFix
      };
    },
    insideClasses() {
      return {
        'i-layout-inside-fix-with-sider': this.siderFix,
        'i-layout-inside-fix-with-sider-collapse': this.siderFix && this.menuCollapse,
        'i-layout-inside-with-hide-sider': this.hideSider,
        'i-layout-inside-mobile': this.isMobile
      };
    },
    drawerClasses() {
      let className = 'i-layout-drawer';
      if (this.siderTheme === 'dark') className += ' i-layout-drawer-dark';
      return className;
    },
    menuSideWidth() {
      return this.menuCollapse ? _setting.default.menuSideCollapseWidth : _setting.default.menuSideWidth;
    },
    pageNoSider() {
      console.log('pageNoSider', !!this.$route.meta.noSider);
      return !!this.$route.meta.noSider;
    }
  },
  watch: {
    hideSider() {
      this.isDelayHideSider = true;
      setTimeout(() => {
        this.isDelayHideSider = false;
      }, 0);
    },
    '$route': {
      handler: function (to, from) {
        if (to.name === from.name) {
          // 相同路由，不同参数，跳转时，重载页面
          if (_setting.default.sameRouteForceUpdate) {
            this.handleReload();
          }
        }
      }
    },
    '$route.name': {
      handler: function (to, from) {
        this.menuList = null;
        this.showImageDropdown = false;
        if (to === 'imagesWriting') {
          // this.getSwitchStatus()
          this.showImageDropdown = true;
        } else if (to === 'audioWriting') {
          this.menuList = this.audioMenuList;
        } else if (to === 'textWriting') {
          this.menuList = this.creationMode;
        } else if (to === 'analyzeWriting') {
          this.menuList = this.pdfAiList;
        } else if (to === 'authorityManagement') {
          this.menuList = this.authMenuList;
        } else if (to === 'threeDModeling') {
          this.menuList = this.threeDModelList;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...(0, _vuex.mapMutations)('admin/layout', ['updateMenuCollapse']),
    ...(0, _vuex.mapMutations)('admin/page', ['keepAlivePush', 'keepAliveRemove']),
    ...(0, _vuex.mapMutations)(['setImageTab', 'setAudioTab', 'setTextTab', 'setAnalyzeTab', 'setAuthTab', 'setThreeDModelTab']),
    handleToggleDrawer(state) {
      if (typeof state === 'boolean') {
        this.showDrawer = state;
      } else {
        this.showDrawer = !this.showDrawer;
      }
    },
    handleScroll() {
      if (!this.headerHide) return;
      const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
      if (!this.ticking) {
        this.ticking = true;
        (0, _util.requestAnimation)(() => {
          if (this.oldScrollTop > scrollTop) {
            this.headerVisible = true;
          } else if (scrollTop > 300 && this.headerVisible) {
            this.headerVisible = false;
          } else if (scrollTop < 300 && !this.headerVisible) {
            this.headerVisible = true;
          }
          this.oldScrollTop = scrollTop;
          this.ticking = false;
        });
      }
    },
    handleHeaderWidthChange() {
      const $breadcrumb = this.$refs.breadcrumb;
      if ($breadcrumb) {
        $breadcrumb.handleGetWidth();
        $breadcrumb.handleCheckWidth();
      }
      const $menuHead = this.$refs.menuHead;
      if ($menuHead) {
        // todo $menuHead.handleGetMenuHeight();
      }
    },
    handleReload() {
      // 针对缓存的页面也生效
      const isCurrentPageCache = this.keepAlive.indexOf(this.$route.name) > -1;
      const pageName = this.$route.name;
      if (isCurrentPageCache) {
        this.keepAliveRemove(pageName);
      }
      this.loadRouter = false;
      (0, _vue.nextTick)(() => {
        this.loadRouter = true;
        if (isCurrentPageCache) {
          this.keepAlivePush(pageName);
        }
      });
    },
    switchMenu(item) {
      this.menuList.forEach(l => {
        l.active = false;
      });
      item.active = true;
      if (item.name) {
        if (this.imgMenuList.map(i => i.name).includes(item.name)) {
          this.setImageTab(item.name);
        } else if (this.audioMenuList.map(i => i.name).includes(item.name)) {
          this.setAudioTab(item.name);
        } else if (this.creationMode.map(i => i.name).includes(item.name)) {
          this.setTextTab(item.name);
        } else if (this.pdfAiList.map(i => i.name).includes(item.name)) {
          this.setAnalyzeTab(item.name);
        } else if (this.authMenuList.map(i => i.name).includes(item.name)) {
          this.setAuthTab(item.name);
        } else if (this.threeDModelList.map(i => i.name).includes(item.name)) {
          this.setThreeDModelTab(item.name);
        }
      }
    },
    // 获取定制模型状态
    async getSwitchStatus() {
      try {
        const res = await (0, _imageWriting.getSdSwitch)();
        if (res.code === 10000) {
          this.switchStatus = res.payload.sd_switch;
          if (!this.switchStatus) {
            this.menuList = this.menuList.filter(item => {
              return item.name !== '定制模型';
            });
          }
        }
      } catch (error) {}
    },
    closeTip() {
      this.showTip = false;
      (0, _home.readNotice)();
    },
    fetchTip() {
      (0, _home.getNotice)().then(res => {
        if (res.data.is_new) {
          this.tip = res.data.words.words;
          this.showTip = true;
        }
      });
    },
    handleEnterSider() {
      this.updateMenuCollapse(false);
    },
    handleLeaveSider() {
      this.updateMenuCollapse(true);
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, {
      passive: true
    });
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    this.updateMenuCollapse(true);
    this.fetchTip();
  }
};
exports.default = _default;
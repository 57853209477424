"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.waterMark.show ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", {
    key: 0,
    style: (0, _vue.normalizeStyle)($options.styles)
  }, null, 4 /* STYLE */)) : (0, _vue.createCommentVNode)("v-if", true);
}
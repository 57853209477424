"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  path: '/analyze',
  title: '智能分析',
  header: 'home',
  custom: 'iconfont icon-analyze'
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _menuTitle = _interopRequireDefault(require("./menu-title"));
var _clickItem = _interopRequireDefault(require("../mixins/click-item"));
var _siderMenuBadge = _interopRequireDefault(require("../mixins/sider-menu-badge"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'iMenuSideItem',
  components: {
    iMenuSideTitle: _menuTitle.default
  },
  mixins: [_clickItem.default, _siderMenuBadge.default],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...(0, _vuex.mapMutations)(['setImageTab']),
    customClick(menu) {
      if (menu.path == '/image') {
        this.setImageTab('查看全部工具');
      }
      this.handleClick(menu.path);
    }
  }
};
exports.default = _default;
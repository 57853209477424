"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./modules/admin"));
var _user = _interopRequireDefault(require("./modules/user"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = (0, _vuex.createStore)({
  modules: {
    admin: _admin.default,
    user: _user.default
  },
  state: {
    imageTab: '查看全部工具',
    imageTabData: null,
    audioTab: '语音合成',
    textTab: '自由创作',
    analyzeTab: '知识库',
    threeDTab: '文生3D',
    authTab: '文案管理',
    userScore: localStorage.getItem('score') || 0,
    profileData: null
  },
  mutations: {
    setImageTab(state, payload) {
      state.imageTab = payload;
      //这里会默认清除掉imageTabData 以防止不该收到的页面收到传递数据
      //如果需要设置传递数据 请再调用setImageTabData
      state.imageTabData = null;
    },
    setImageTabData(state, payload) {
      state.imageTabData = payload;
    },
    setAudioTab(state, payload) {
      state.audioTab = payload;
    },
    setTextTab(state, payload) {
      state.textTab = payload;
    },
    setAnalyzeTab(state, payload) {
      state.analyzeTab = payload;
    },
    setThreeDModelTab(state, payload) {
      state.threeDTab = payload;
    },
    setAuthTab(state, payload) {
      state.authTab = payload;
    },
    setUserScore(state, payload) {
      state.userScore = payload;
    },
    setProfileData(state, payload) {
      state.profileData = payload;
    }
  },
  actions: {}
});
exports.default = _default;
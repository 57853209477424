"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deadlineUsers = deadlineUsers;
exports.getIntroduction = getIntroduction;
exports.getNotice = getNotice;
exports.getUsers = getUsers;
exports.operateUsers = operateUsers;
exports.queryUsers = queryUsers;
exports.setIntroduction = setIntroduction;
exports.setNotice = setNotice;
exports.userExport = userExport;
exports.verifyUsers = verifyUsers;
var _request = _interopRequireDefault(require("@/plugins/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 获取用户列表
function getUsers(data) {
  return (0, _request.default)({
    url: '/api/authority/get_users/v2',
    method: 'post',
    data
  });
}

// 对用户进行权限认证操作
function verifyUsers(data) {
  return (0, _request.default)({
    url: '/api/authority/verify',
    method: 'post',
    data
  });
}

// 对用户进行操作、增加或者删除
function operateUsers(data) {
  return (0, _request.default)({
    url: '/api/authority/operate_user/v2',
    method: 'post',
    data
  });
}

//搜索用户
function queryUsers(data) {
  return (0, _request.default)({
    url: '/api/authority/query',
    method: 'post',
    data
  });
}

//编辑用户时间期限
function deadlineUsers(data) {
  return (0, _request.default)({
    url: '/api/authority/deadline',
    method: 'post',
    data
  });
}

//导出用户数据
function userExport(data) {
  return (0, _request.default)({
    url: '/api/authority/export_users',
    method: 'post',
    responseType: 'blob',
    data
  });
}
function getIntroduction() {
  return (0, _request.default)({
    url: '/api/authority/introduction/words',
    method: 'get'
  });
}
function getNotice() {
  return (0, _request.default)({
    url: '/api/authority/notice/words',
    method: 'get'
  });
}
function setIntroduction(data) {
  return (0, _request.default)({
    url: '/api/authority/introduction/words',
    method: 'post',
    data
  });
}
function setNotice(data) {
  return (0, _request.default)({
    url: '/api/authority/notice/words',
    method: 'post',
    data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", {
    class: "avatar",
    style: (0, _vue.normalizeStyle)({
      background: $data.bgColor
    })
  }, (0, _vue.toDisplayString)($data.name), 5 /* TEXT, STYLE */);
}
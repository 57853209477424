"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _textWriting = _interopRequireDefault(require("./modules/text-writing"));
var _imagesWriting = _interopRequireDefault(require("./modules/images-writing"));
var _audioWriting = _interopRequireDefault(require("./modules/audio-writing"));
var _pdfAnalyze = _interopRequireDefault(require("./modules/pdf-analyze"));
var _pdfDemo = _interopRequireDefault(require("./modules/pdf-demo"));
var _dModeling = _interopRequireDefault(require("./modules/3d-modeling"));
var _authorityManagement = _interopRequireDefault(require("./modules/authority-management"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 菜单，侧边栏
// 系统
var _default = [_textWriting.default, ..._imagesWriting.default, _audioWriting.default, _dModeling.default, _pdfAnalyze.default,
// pdfDemo,
_authorityManagement.default];
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const pre = '/auth/';
var _default = {
  path: '/auth',
  title: '权限管理',
  header: 'home',
  custom: 'iconfont icon-admin',
  checkDisPlay: () => {
    console.log("localStorage.getItem('is_manager') === 'true'", localStorage.getItem('is_manager') === 'true');
    return localStorage.getItem('is_manager') === 'true';
  }
};
exports.default = _default;
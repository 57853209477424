"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 0
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = (0, _vue.resolveComponent)("Icon");
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("span", {
    class: (0, _vue.normalizeClass)(["i-layout-menu-side-title", {
      'i-layout-menu-side-title-with-collapse': $props.collapse
    }])
  }, [$options.withIcon ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("span", {
    key: 0,
    class: (0, _vue.normalizeClass)(["i-layout-menu-side-title-icon", {
      'i-layout-menu-side-title-icon-single': $props.hideTitle
    }])
  }, [$props.menu.icon ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Icon, {
    key: 0,
    type: $props.menu.icon
  }, null, 8 /* PROPS */, ["type"])) : $props.menu.custom ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Icon, {
    key: 1,
    custom: $props.menu.custom
  }, null, 8 /* PROPS */, ["custom"])) : $props.menu.img ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", {
    key: 2,
    src: $props.menu.img,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_1)) : (0, _vue.createCommentVNode)("v-if", true)], 2 /* CLASS */)) : (0, _vue.createCommentVNode)("v-if", true), !$props.hideTitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("span", {
    key: 1,
    class: (0, _vue.normalizeClass)(["i-layout-menu-side-title-text", {
      'i-layout-menu-side-title-text-selected': $props.selected,
      'i-layout-menu-side-title-text-with-subtitle': $props.menu.subtitle,
      'i-layout-menu-side-title-text-with-icon': $options.withIcon
    }])
  }, [(0, _vue.createTextVNode)((0, _vue.toDisplayString)(_ctx.tTitle($props.menu.title)), 1 /* TEXT */), $props.menu.subtitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("em", _hoisted_2, (0, _vue.toDisplayString)(_ctx.tTitle($props.menu.subtitle)), 1 /* TEXT */)) : (0, _vue.createCommentVNode)("v-if", true)], 2 /* CLASS */)) : (0, _vue.createCommentVNode)("v-if", true)], 2 /* CLASS */);
}
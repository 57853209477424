"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _default = {
  __name: 'index',
  props: {
    info: {
      type: Object
    },
    mode: {
      type: String,
      default: ''
      //默认为空字符串 可传'dropdown'
    }
  },

  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    const video = (0, _vue.ref)(null);
    const handleMouseenter = () => {
      video.value.play();
    };
    const handleMouseleave = () => {
      video.value.pause();
      video.value.currentTime = 0;
    };
    const __returned__ = {
      props,
      video,
      handleMouseenter,
      handleMouseleave,
      ref: _vue.ref
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.getUserInfo = getUserInfo;
exports.handleLogOut = handleLogOut;
var _request = _interopRequireDefault(require("@/plugins/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 获取登录token
function getToken(data) {
  return (0, _request.default)({
    url: '/api/token',
    method: 'post',
    data
  });
}

// 获取用户信息
function getUserInfo(data) {
  return (0, _request.default)({
    url: '/api/user/info',
    method: 'post',
    data
  });
}

// 点击退出登录时调用
function handleLogOut(params) {
  return (0, _request.default)({
    url: '/api/logout',
    method: 'post',
    params
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const meta = {
  title: '三维模型',
  auth: true
};

//   const pre = "audio-";
var _default = {
  path: "/model",
  name: "threeDModeling",
  meta,
  component: () => import('@/pages/3d-modeling')
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _locale = _interopRequireDefault(require("@/i18n/locale"));
var _setting = _interopRequireDefault(require("@/setting"));
var _util = _interopRequireDefault(require("@/libs/util"));
var _db = require("@/store/modules/admin/modules/db");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 多语言
 * */

const savedLocaleKey = 'i18n-locale';
var _default = {
  namespaced: true,
  state: {
    locale: ''
  },
  actions: {
    /**
     * @description 获取当前语言
     * */
    getLocale({
      state
    }) {
      let locale;
      const db = _util.default.db.get((0, _db.pathInit)({
        dbName: 'database',
        path: '',
        user: true,
        defaultValue: {}
      }));
      const savedLocale = db.get(savedLocaleKey).value();

      // 先判断本地存储是否已有语言选择
      if (savedLocale) {
        locale = savedLocale;
      } else {
        // 判断是否开启自动识别语言
        if (_setting.default.i18n.auto) {
          // 如果自动识别的语言，本地没有该语言包，则设置为默认语言
          const navLang = navigator.language;
          if (_locale.default[navLang]) {
            locale = navLang;
          } else {
            locale = _setting.default.i18n.default;
          }
        } else {
          locale = _setting.default.i18n.default;
        }

        // 将初次的语言保存在本地
        db.set(savedLocaleKey, locale).write();
      }
      state.locale = locale;
    },
    /**
     * @description 设置当前语言
     * */
    setLocale({
      state
    }, {
      locale = _setting.default.i18n.default,
      vm
    }) {
      const db = _util.default.db.get((0, _db.pathInit)({
        dbName: 'database',
        path: '',
        user: true,
        defaultValue: {}
      }));

      // 将语言保存在本地
      db.set(savedLocaleKey, locale).write();

      // 判断是否刷新页面
      if (_setting.default.i18n.refresh) {
        location.reload();
      } else {
        // 设置当前语言
        state.locale = locale;
        // 设置 vue-i18n 的语言
        vm.$i18n.locale = locale;
        // 更新网页标题
        _util.default.title({
          title: vm.$route.meta.title
        });
      }
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _default = {
  computed: {
    ...(0, _vuex.mapState)('admin/menu', ['siderMenuBadge']),
    badgeData() {
      let data = null;
      const siderMenuBadge = this.siderMenuBadge.find(item => item.path === this.menu.path);
      if (siderMenuBadge) data = siderMenuBadge;
      return data;
    }
  }
};
exports.default = _default;
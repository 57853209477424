"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0,
  class: "i-layout-menu-side-collapse-title"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = (0, _vue.resolveComponent)("i-menu-side-title");
  const _component_Icon = (0, _vue.resolveComponent)("Icon");
  const _component_DropdownItem = (0, _vue.resolveComponent)("DropdownItem");
  const _component_i_link = (0, _vue.resolveComponent)("i-link");
  const _component_i_menu_side_collapse = (0, _vue.resolveComponent)("i-menu-side-collapse");
  const _component_DropdownMenu = (0, _vue.resolveComponent)("DropdownMenu");
  const _component_Dropdown = (0, _vue.resolveComponent)("Dropdown");
  return (0, _vue.openBlock)(), (0, _vue.createBlock)(_component_Dropdown, {
    transfer: false,
    "boundaries-element": "viewport",
    placement: "right-start",
    class: (0, _vue.normalizeClass)($options.dropdownClasses)
  }, {
    list: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_DropdownMenu, {
      class: "i-layout-menu-side-collapse-dropdown-menu",
      style: (0, _vue.normalizeStyle)($options.dropdownMenuMaxHeight)
    }, {
      default: (0, _vue.withCtx)(() => [_ctx.showCollapseMenuTitle ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_2, [(0, _vue.createVNode)(_component_i_menu_side_title, {
        menu: $props.menu,
        collapse: ""
      }, null, 8 /* PROPS */, ["menu"])])) : (0, _vue.createCommentVNode)("v-if", true), ((0, _vue.openBlock)(true), (0, _vue.createElementBlock)(_vue.Fragment, null, (0, _vue.renderList)($props.menu.children, (item, index) => {
        return (0, _vue.openBlock)(), (0, _vue.createElementBlock)(_vue.Fragment, {
          key: index
        }, [item.children === undefined || !item.children.length ? ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_link, {
          to: item.path,
          target: item.target,
          key: index,
          onClick: $event => _ctx.handleClick(item.path)
        }, {
          default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_DropdownItem, {
            divided: item.divided,
            class: (0, _vue.normalizeClass)({
              'i-layout-menu-side-collapse-item-selected': item.path === _ctx.activePath
            })
          }, {
            default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side_title, {
              menu: item,
              collapse: ""
            }, null, 8 /* PROPS */, ["menu"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["divided", "class"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "target", "onClick"])) : ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_i_menu_side_collapse, {
          key: 1,
          menu: item
        }, null, 8 /* PROPS */, ["menu"]))], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */))]),

      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style"])]),
    default: (0, _vue.withCtx)(() => [$props.topLevel ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("ul", _hoisted_1, [(0, _vue.createElementVNode)("li", {
      class: (0, _vue.normalizeClass)($options.menuItemClasses)
    }, [(0, _vue.createVNode)(_component_i_menu_side_title, {
      menu: $props.menu,
      "hide-title": "",
      collapse: ""
    }, null, 8 /* PROPS */, ["menu"])], 2 /* CLASS */)])) : ((0, _vue.openBlock)(), (0, _vue.createBlock)(_component_DropdownItem, {
      key: 1
    }, {
      default: (0, _vue.withCtx)(() => [(0, _vue.createVNode)(_component_i_menu_side_title, {
        menu: $props.menu,
        selected: _ctx.openNames.indexOf($props.menu.path) >= 0,
        collapse: ""
      }, null, 8 /* PROPS */, ["menu", "selected"]), (0, _vue.createVNode)(_component_Icon, {
        type: "ios-arrow-forward",
        class: "i-layout-menu-side-arrow"
      })]),
      _: 1 /* STABLE */
    }))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}
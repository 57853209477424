"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  methods: {
    tTitle(title) {
      if (title && title.indexOf('$t:') === 0) {
        return this.$t(title.split('$t:')[1]);
      } else {
        return title;
      }
    }
  }
};
exports.default = _default;
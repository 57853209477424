"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueRouter = require("vue-router");
var _viewUiPlus = _interopRequireDefault(require("view-ui-plus"));
var _util = _interopRequireDefault(require("@/libs/util"));
var _setting = _interopRequireDefault(require("@/setting"));
var _index = _interopRequireDefault(require("@/store/index"));
var _account = require("@api/account");
var _routes = _interopRequireDefault(require("./routes"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 路由数据

// 导出路由 在 main.js 里使用
const router = (0, _vueRouter.createRouter)({
  history: _setting.default.routerMode === 'history' ? (0, _vueRouter.createWebHistory)(_setting.default.routerBase) : _setting.default.routerMode === 'hash' ? (0, _vueRouter.createWebHashHistory)(_setting.default.routerBase) : (0, _vueRouter.createMemoryHistory)(_setting.default.routerBase),
  routes: _routes.default
});

/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(async (to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  if (_setting.default.showProgressBar) _viewUiPlus.default.LoadingBar.start();
  // 如果有code 则使用code换取token
  if (to.query.code && !localStorage.getItem('access_token')) {
    await _index.default.dispatch('user/login', {
      code: to.query.code
    });
  }
  // 判断是否需要登录才可以进入
  if (to.matched.some(_ => _.meta.auth)) {
    // 这里依据 token 判断是否登录，可视情况修改
    if (!localStorage.getItem('access_token')) {
      router.push('/login');
      return;
    }
    if (!window.localStorage.getItem('user_info')) {
      await _index.default.dispatch('user/getUserInfo');
    }
    await _index.default.dispatch('user/verifyUsers');
    _index.default.dispatch('admin/menu/setMenuList', to);
    next();
  } else {
    // 不需要身份校验 直接通过
    next();
  }
});
router.afterEach(to => {
  if (_setting.default.showProgressBar) _viewUiPlus.default.LoadingBar.finish();
  // 多页控制 打开新的页面
  if (!('meta' in to) || to.meta && !('tabs' in to.meta) || to.meta && to.meta.tabs) {
    _index.default.dispatch('admin/page/open', to);
  }
  // 更改标题
  _util.default.title({
    title: to.meta.title
  });
  // 返回页面顶端
  window.scrollTo(0, 0);
});
var _default = router;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
const _hoisted_1 = {
  class: "i-frame"
};
const _hoisted_2 = ["src"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", _hoisted_1, [$data.ready ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("iframe", {
    key: 0,
    src: $props.src,
    frameborder: "0",
    title: "",
    class: "i-frame-iframe"
  }, null, 8 /* PROPS */, _hoisted_2)) : (0, _vue.createCommentVNode)("v-if", true)]);
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createProjectDialog = _interopRequireDefault(require("./components/createProjectDialog.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _account = require("@/api/account.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const scoreArr = [{
  name: '文案创作',
  score: '-1'
}, {
  name: '图片创作',
  score: '-18'
}, {
  name: '音频创作',
  score: '-5'
}, {
  name: '三维模型',
  score: '-25'
}, {
  name: '智能分析',
  score: '-4'
}];
var _default = {
  name: "iHeaderAccount",
  components: {
    createProjectDialog: _createProjectDialog.default
  },
  data() {
    return {
      visible: true,
      // score: 0,
      scoreArr: scoreArr,
      teamList: [{
        name: "编辑部的团队"
      }, {
        name: "市场部的团队"
      }],
      profileData: {},
      profileItem: {
        name: "个人",
        score: 0
      },
      addProjectDialog: false,
      loading: false
    };
  },
  mounted() {
    this.getUserInfo();
    if (window.localStorage.getItem("tipsClose")) {
      console.log(window.localStorage.getItem("tipsClose"));
      this.visible = false;
    }
  },
  computed: {
    score() {
      return this.$store.state.userScore;
    }
  },
  watch: {
    "$router.currentRoute.value.path": function (newV, oldV) {
      console.log(newV, oldV, "watss");
      if (this.$router.currentRoute.value.path == "/home" && !window.localStorage.getItem("tipsClose")) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }
  },
  methods: {
    // 获取当前用户信息
    getUserInfo() {
      (0, _account.getProfileInfo)().then(res => {
        console.log(res, "rr");
        this.profileData = res.payload;
        this.teamList = res.payload.groups;
        this.profileItem = res.payload.current_group_id && res.payload.groups.length ? res.payload.groups.filter(item => item.gid == res.payload.current_group_id)[0] : {
          name: "个人",
          score: res.payload.score
        };
        console.log(this.profileItem, "item");
        window.localStorage.setItem("current_group_id", res.payload.current_group_id);
        window.localStorage.setItem("score", this.profileItem.score);
        this.$store.commit("setUserScore", this.profileItem.score);
        this.$store.commit("setProfileData", this.profileItem);
      });
    },
    // 创建用户组
    async createGroup(data) {
      try {
        const res = await (0, _account.addProfileGroup)(data);
        console.log(res, "add");
        if (res.code == 20001) {
          this.loading = false;
          this.$Message.success("申请提交成功，请等待审核");
          this.addProjectDialog = false;
          // this.getUserInfo()
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 切换所在组
    async onChange(item) {
      if (this.profileItem.gid == item.gid) return;
      try {
        const res = await (0, _account.switchGroup)({
          target: item.gid || ""
        });
        console.log(res, "sw");
        if (res.code == 20001) {
          this.getUserInfo();
          this.$Message.success("切换成功");
        }
      } catch (error) {
        console.log(error, "er");
      }
    },
    // 创建
    submit(val) {
      this.loading = true;
      console.log(val);
      const data = {
        brand_name: val.brand_name,
        project_name: val.project_name,
        product_start_date: val.timeDate[0],
        project_end_date: val.timeDate[1],
        usage: val.usage,
        members: val.members.split("\n")
      };
      console.log(data);
      this.createGroup(data);
    },
    create() {
      this.addProjectDialog = true;
    },
    myTeam() {
      _router.default.push({
        path: "/team"
      });
    },
    manege(row) {
      _router.default.push({
        path: "/team",
        query: {
          id: row.gid
        }
      });
    },
    close() {
      this.visible = false;
      window.localStorage.setItem("tipsClose", true);
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.render = render;
var _vue = require("vue");
var _side_newIcon = _interopRequireDefault(require("@/assets/images/side_newIcon.png"));
var _side_updateIcon = _interopRequireDefault(require("@/assets/images/side_updateIcon.png"));
var _test_icon = _interopRequireDefault(require("@/assets/images/test_icon.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _withScopeId = n => ((0, _vue.pushScopeId)("data-v-464053e8"), n = n(), (0, _vue.popScopeId)(), n);
const _hoisted_1 = {
  class: "video-box"
};
const _hoisted_2 = {
  ref: "video",
  class: "video",
  muted: "",
  loop: "",
  playsinline: ""
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "content-box"
};
const _hoisted_5 = {
  class: "header row ai-center"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  key: 0,
  src: _side_newIcon.default,
  alt: "",
  style: {
    "width": "22px",
    "height": "11px",
    "margin-left": "6px",
    "vertical-align": "top",
    "transform": "translateY(-12px)"
  }
};
const _hoisted_9 = {
  key: 1,
  src: _side_updateIcon.default,
  alt: "",
  style: {
    "width": "35px",
    "height": "11px",
    "margin-left": "6px",
    "vertical-align": "top",
    "transform": "translateY(-12px)"
  }
};
const _hoisted_10 = {
  key: 2,
  src: _test_icon.default,
  alt: "",
  style: {
    "width": "35px",
    "height": "11px",
    "margin-left": "6px",
    "vertical-align": "top",
    "transform": "translateY(-12px)"
  }
};
const _hoisted_11 = {
  class: "desc"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (0, _vue.openBlock)(), (0, _vue.createElementBlock)("div", {
    class: (0, _vue.normalizeClass)(["tool-viewer-container column jc-end", $props.mode]),
    onMouseenter: $setup.handleMouseenter,
    onMouseleave: $setup.handleMouseleave
  }, [(0, _vue.createElementVNode)("div", _hoisted_1, [(0, _vue.createElementVNode)("video", _hoisted_2, [(0, _vue.createElementVNode)("source", {
    src: $props.info.video,
    type: "video/mp4"
  }, null, 8 /* PROPS */, _hoisted_3)], 512 /* NEED_PATCH */)]), (0, _vue.createElementVNode)("div", _hoisted_4, [(0, _vue.createElementVNode)("div", _hoisted_5, [(0, _vue.createElementVNode)("img", {
    class: "icon",
    src: $props.info.icon,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_6), (0, _vue.createElementVNode)("div", _hoisted_7, (0, _vue.toDisplayString)($props.info.title), 1 /* TEXT */), $props.mode != _ctx.dropdown && $props.info.isNew ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_8)) : (0, _vue.createCommentVNode)("v-if", true), $props.mode != _ctx.dropdown && $props.info.isUpdate ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_9)) : (0, _vue.createCommentVNode)("v-if", true), $props.mode != _ctx.dropdown && $props.info.isTest ? ((0, _vue.openBlock)(), (0, _vue.createElementBlock)("img", _hoisted_10)) : (0, _vue.createCommentVNode)("v-if", true)]), (0, _vue.createElementVNode)("div", _hoisted_11, (0, _vue.toDisplayString)($props.info.desc), 1 /* TEXT */)])], 34 /* CLASS, HYDRATE_EVENTS */);
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _waterMark = _interopRequireDefault(require("@/libs/water-mark"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  name: 'i-water-mark',
  computed: {
    ...(0, _vuex.mapState)('admin/layout', ['waterMark']),
    styles() {
      const url = (0, _waterMark.default)(this.waterMark.text, this.waterMark.options);
      return {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        'z-index': '2000',
        'background-size': '320px',
        'background-repeat': 'repeat',
        'background-image': `url(${url})`,
        'pointer-events': 'none'
      };
    }
  }
};
exports.default = _default;
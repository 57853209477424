"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _lodash = require("lodash");
var _store = _interopRequireDefault(require("@/store"));
var _util = _interopRequireDefault(require("@/libs/util"));
var _setting = _interopRequireDefault(require("@/setting"));
var _viewUiPlus = require("view-ui-plus");
var _testUtils = require("@vue/test-utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// 创建一个错误
function errorCreate(msg) {
  const err = new Error(msg);
  errorLog(err);
  throw err;
}

// 记录和显示错误
function errorLog(err) {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    _util.default.log.error('>>>>>> Error >>>>>>');
    console.log(err);
  }
  if (err.response.status == 502) {
    _viewUiPlus.Modal.error({
      title: '服务繁忙',
      content: err.message
    });
  }
}

// 创建一个 axios 实例
const service = _axios.default.create({
  baseURL: _setting.default.request.apiBaseURL,
  timeout: _setting.default.request.timeout,
  withCredentials: true
});

// 请求拦截器
service.interceptors.request.use(config => {
  const tokenStr = window.localStorage.getItem("access_token");
  if (config.requestBase && config.requestBase == "WPP_API") {
    config.baseURL = _setting.default.request.wppBaseURL;
    if (tokenStr) config.headers.common["Authorization"] = `Bearer ${tokenStr}`;
  } else {
    if (tokenStr) {
      config.headers.common["token"] = tokenStr;
    }
  }
  return (0, _lodash.merge)(config, _setting.default.request.requestConfig(config, _util.default));
}, error => {
  // 发送失败
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response => {
  // dataAxios 是 axios 返回数据中的 data
  const dataAxios = response.data;
  // 这个状态码是和后端约定的
  const {
    code
  } = dataAxios;
  // 根据 code 进行判断
  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口
    return dataAxios;
  } else {
    console.log(dataAxios);
    console.log(code);
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      // case 0:
      //     // [ 示例 ] code === 0 代表没有错误
      //     return dataAxios.data;
      case 401:
        //     // [ 示例 ] 其它和后台约定的 code
        //     errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
        _store.default.dispatch('admin/account/logout', {});
        // 跳转到登录页面
        window.location.href = '/login';
        break;
      case 20005:
        _viewUiPlus.Notice.error({
          title: dataAxios.message,
          desc: '',
          duration: 0
        });
        break;
      // default:
      //     // 不是正确的 code
      //     errorCreate(`${dataAxios.msg}: ${response.config.url}`);
      //     break;
    }

    return dataAxios;
  }
}, error => {
  console.log(error.response);
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权，请登录';
        _store.default.dispatch('admin/account/logout', {});
        window.location.href = '/login';
        break;
      case 403:
        error.message = '拒绝访问';
        break;
      case 404:
        error.message = `请求地址出错: ${error.response.config.url}`;
        break;
      case 408:
        error.message = '请求超时';
        break;
      case 413:
        error.message = '上传文件大小超限,您上传的文件大小应控制在50MB以内。';
        break;
      case 500:
        error.message = '功能异常,请您联系管理员协助处理';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '现在使用的人太多了，请您稍后再进行尝试！';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '此功能等待超时，请您稍后再进行尝试！';
        break;
      case 505:
        error.message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
}, error => {
  console.log(error.response);
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = "请求错误";
        break;
      case 401:
        error.message = "未授权，请登录";
        _store.default.context.dispatch("user/clear");
        window.location.href = "/login";
        break;
      case 403:
        error.message = "拒绝访问";
        break;
      case 404:
        error.message = `请求地址出错: ${error.response.config.url}`;
        break;
      case 408:
        error.message = "请求超时";
        break;
      case 413:
        error.message = "上传文件大小超限,您上传的文件大小应控制在50MB以内。";
        break;
      case 500:
        error.message = "功能异常,请您联系管理员协助处理";
        break;
      case 501:
        error.message = "服务未实现";
        break;
      case 502:
        error.message = "现在使用的人太多了，请您稍后再进行尝试！";
        break;
      case 503:
        error.message = "服务不可用";
        break;
      case 504:
        error.message = "此功能等待超时，请您稍后再进行尝试！";
        break;
      case 505:
        error.message = "HTTP版本不受支持";
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;
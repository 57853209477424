"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.frameInRoutes = exports.default = void 0;
var _vue = require("vue");
var _textWriting = _interopRequireDefault(require("./modules/text-writing"));
var _imagesWriting = _interopRequireDefault(require("./modules/images-writing"));
var _audioWriting = _interopRequireDefault(require("./modules/audio-writing"));
var _analyzeWriting = _interopRequireDefault(require("./modules/analyze-writing"));
var _pdfDemo = _interopRequireDefault(require("./modules/pdf-demo"));
var _dModeling = _interopRequireDefault(require("./modules/3d-modeling"));
var _basicLayout = _interopRequireDefault(require("@/layouts/basic-layout"));
var _authorityManagement = _interopRequireDefault(require("./modules/authority-management"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import marketing from './modules/marketing';

//图片创作
//图片创作
//音频创作

// 三维模型

/**
 * 在主框架内显示
 */

const frameIn = [{
  path: '/',
  redirect: {
    name: 'home'
  },
  component: _basicLayout.default,
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'home',
      auth: true
    }
  }, {
    path: 'home',
    name: 'home',
    meta: {
      title: '欢迎',
      noSider: true,
      auth: true
    },
    component: () => import('@/pages/home')
  }, {
    path: 'team',
    name: 'team',
    meta: {
      title: '团队管理',
      noSider: true,
      auth: true
    },
    component: () => import('@/pages/team')
  }, {
    path: 'log',
    name: 'system-log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: () => import('@/pages/system/log')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        next(instance => instance.$router.replace(from.fullPath));
      },
      render: () => (0, _vue.h)()
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter(to, from, next) {
        next(instance => instance.$router.replace(JSON.parse(from.params.route)));
      },
      render: () => (0, _vue.h)()
    }
  }, _textWriting.default, ..._imagesWriting.default, _audioWriting.default, _analyzeWriting.default, _dModeling.default, _authorityManagement.default, _pdfDemo.default]
}];

/**
 * 在主框架之外显示
 */

const frameOut = [{
  path: '/test',
  name: 'test',
  component: () => import('@/pages/test')
},
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '登录'
  },
  component: () => import('@/pages/login')
}];

/**
 * 错误页面
 */

const errorPage = [{
  path: '/maintenance',
  name: 'maintenance',
  meta: {
    title: '维护中'
  },
  component: () => import('@/pages/maintenance')
}, {
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: () => import('@/pages/system/error/403')
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: () => import('@/pages/system/error/500')
}, {
  path: '/:pathMatch(.*)',
  name: '404',
  meta: {
    title: '404'
  },
  component: () => import('@/pages/system/error/404')
}, {
  path: '/error',
  name: 'error',
  meta: {
    title: 'error'
  },
  component: () => import('@/pages/error')
}];
// 导出需要显示菜单的
const frameInRoutes = frameIn;

// 重新组织后导出
exports.frameInRoutes = frameInRoutes;
var _default = [...frameIn, ...frameOut, ...errorPage];
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _system = require("@/libs/system");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 鉴权指令
 * 当传入的权限当前用户没有时，会移除该组件
 * 用例：<Tag v-auth="['admin']">text</Tag>
 * */
var _default = {
  mounted(el, binding, vnode) {
    const {
      value
    } = binding;
    const access = _store.default.state.admin.user.info.access;
    let isPermission = false;
    if (value && value instanceof Array && value.length && access && access.length) {
      isPermission = (0, _system.includeArray)(value, access);
    }
    if (!isPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};
exports.default = _default;
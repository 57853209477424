"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const meta = {
  title: '智能分析',
  auth: true
};

//   const pre = "audio-";
var _default = {
  path: "/analyze",
  name: "analyzeWriting",
  meta,
  component: () => import('@/pages/analyze-writing')
};
exports.default = _default;
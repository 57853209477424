"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const meta = {
  title: '知识库',
  auth: true
};

//   const pre = "audio-";
var _default = {
  path: "/demo",
  name: "demo",
  meta,
  component: () => import('@/pages/pdf-demo/index')
};
exports.default = _default;
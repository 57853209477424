"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _store = _interopRequireDefault(require("@/store"));
var _util = _interopRequireDefault(require("@/libs/util"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  install(app, options) {
    app.config.errorHandler = function (error, instance, info) {
      (0, _vue.nextTick)(() => {
        // store 追加 log
        _store.default.dispatch('admin/log/push', {
          message: `${info}: ${error.message}`,
          type: 'error',
          meta: {
            error
            // instance
          }
        });
        // 只在开发模式下打印 log
        if (process.env.NODE_ENV === 'development') {
          _util.default.log.capsule('Admin Plus', 'ErrorHandler', 'error');
          _util.default.log.error('>>>>>> 错误信息 >>>>>>');
          console.log(info);
          _util.default.log.error('>>>>>> Vue 实例 >>>>>>');
          console.log(instance);
          _util.default.log.error('>>>>>> Error >>>>>>');
          console.log(error);
        }
      });
    };
  }
};
exports.default = _default;
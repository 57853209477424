"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  'zh-CN': {
    exception: {
      e403: '抱歉，你无权访问该页面',
      e404: '抱歉，你访问的页面不存在',
      e500: '抱歉，服务器出错了',
      btn: '返回首页'
    }
  },
  'en-US': {
    exception: {
      e403: 'Sorry, you don\'t have access to this page.',
      e404: 'Sorry, the page you visited does not exist.',
      e500: 'Sorry, the server is reporting an error.',
      btn: 'Back to home'
    }
  }
};
exports.default = _default;